/* Only opacity: */
.content-fade-appear,
.content-fade-enter {
  opacity: 0;
}
.content-fade-exit {
  opacity: 1;
}
.content-fade-appear-active,
.content-fade-enter-active {
  opacity: 1;
}
.content-fade-exit-active {
  opacity: 0;
}
.content-fade-enter-active,
.content-fade-exit-active {
  transition: opacity 600ms;
}
.content-fade-appear-active {
  transition: opacity 1000ms;
}

/* Opacity and transition: */
/* .content-fade-appear,
.content-fade-enter {
  opacity: 0;
  transform: translateX(-20%);
}
.content-fade-exit {
  opacity: 1;
}
.content-fade-appear-active,
.content-fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.content-fade-exit-active {
  opacity: 0;
  transform: translateX(20%);
}
.content-fade-enter-active,
.content-fade-exit-active {
  transition: opacity 1000ms, transform 1000ms;
}
.content-fade-appear-active {
  transition: opacity 1000ms, transform 1000ms;
} */
